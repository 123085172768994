import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout/Layout.js"
import { Row } from "../components/row/Row.js"
import { Subtitle, Title } from "../components/title/Title.js"
import { Section } from "../components/section/Section"
import BlogSubscribe from "../components/blogSubscribe/BlogSubscribe.js"
import Seo from "../components/seo"
import Cta from "../components/ctablock/Cta"
import { CourseSnippet } from "../components/courseSnippet/CourseSnippet"
import featuredImage from "../static/blogImages/undraw_real_time_collaboration_c62i.png"
import { Column100, Column50 } from '../components/column/Column.js'
import { Testimonials} from '../components/testimonial/Testimonial.js'

function Training({ children, isCentered, ...rest }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
      allContentfulTraining(sort: { fields: date, order: DESC }) {
        edges {
          node {
            id
            courseBanner {
              gatsbyImageData(width:200)
              id
              title
              file {
                url
              }
            }
            courseContent {
              childMarkdownRemark {
                html
              }
            }
            date(formatString: "Do MMMM, YYYY")
            duration
            price
            level
            title
            subtitle
            slug
            courseLink
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Content Strategy Training" 
      description="We offer content and marketing strategy courses for all levels, from beginner to advanced. Find the course that fits your needs below."      
       image={featuredImage}
       featuredImage={featuredImage} />

       <BlogSubscribe/>

       <Section isCentered>
        <Row isCentered>
          <Title isPrimary title="On-demand courses" />
        </Row>
        <Row isCentered>
          {data.allContentfulTraining.edges.map(({ node }) => (
            <Column50 key={node.id}>
              <CourseSnippet
                snippetTitle={node.title}
                snippetText={node.subtitle}
                imageAlt="image"
                snippetImage={node.courseBanner.file.url}
                href={`/content-strategy-training/${node.slug}/`}
                anchorText="See details"
              />
            </Column50>
          ))}
        </Row>
      </Section>

      <Testimonials/>

      <Section isCentered>    
        <Row isCentered>
         <Cta />
        </Row>
      </Section>
     
    </Layout>
  )
}

export default Training

import React from "react"
import {
  SnippetWrapper,
  SnippetText,
  SnippetTitle,
  AuthorWrapper,
  SnippetImage,
} from "./style.js"
import { Link } from "../link/Link"

export const CourseSnippet = ({ ...props }) => (
  <SnippetWrapper isLight={props.isLight} isCentered={props.isCentered}>
    <SnippetImage src={props.snippetImage} alt={props.imageAlt} />
    <SnippetTitle isCentered={props.isCentered}>{props.snippetTitle} </SnippetTitle>
    <SnippetText>{props.snippetText}</SnippetText>
    <Link isCentered={props.isCentered} anchortext={props.anchorText} href={props.href} />
  </SnippetWrapper>
)

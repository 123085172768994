import styled from "styled-components"
import { colors } from "../../styles/colors.js"
import { sizes, device } from "../../styles/sizes"
import { fontSizes } from "../../styles/typography"
import { StyledH3 } from "../title/style"

export const TestimonialWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => (props.isCentered ? "stretch" : "flex-start")};
  align-items: center;
  padding: ${sizes.sizeXL} ${sizes.sizeXXL};
  border-radius: 8px;
  border: 2px solid #C6B1BD;
  font-weight: 500;
  background-color: ${props =>
    props.isLight ? `${colors.colorTextLightest}` : `${colors.colorWhite}`};
  color: ${colors.colorTextPurple};
  width: auto;

  :first-child {
    margin-bottom: 50px;
  }

  @media ${device.tablet} {
    padding: ${sizes.sizeXL} ${sizes.sizeXXXXL};
    margin-bottom: 0px;

      :first-child {
      margin-bottom: 50px;
    }
  }

  @media ${device.laptop} {
    padding: ${sizes.sizeXL} ${sizes.sizeXXXXL};
    justify-content: space-between;
  }

  @media ${device.desktop} {
    padding: ${sizes.sizeXL} ${sizes.sizeXXXXL};

      :first-child {
      margin-bottom: 0px;
    }
  }
`

export const TestimonialTitle = styled(StyledH3)`
  font-size: ${fontSizes.fontL};
  margin-bottom: ${sizes.sizeL};
  line-height: ${fontSizes.fontXL};
`

export const TestimonialText = styled.p`
  font-size: ${fontSizes.fontM};
  margin-bottom: ${sizes.sizeL};
  line-height: ${fontSizes.fontL};
`

export const TestimonialtImage = styled.img`
  object-fit: cover;
  object-position: center center;
  height: 300px;
  width: 100%;
  border-radius: 2rem;
  margin-bottom: ${sizes.sizeS};
`

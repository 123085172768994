import React from "react"
import {
  TestimonialWrapper,
  TestimonialText,
} from "./style.js"
import { Client } from "../client/Client.js"
import { Section } from "../section/Section.js"
import { Row } from "../row/Row.js"
import { Column50 } from "../column/Column.js"
import { Subtitle } from "../title/Title.js"


export const Testimonial = ({ ...props }) => (
  <TestimonialWrapper isLight={props.isLight} isCentered={props.isCentered}>
    <TestimonialText>{props.testimonialText}</TestimonialText>
    <Client clientName={props.clientName} clientAvatar={props.clientAvatar} clientTitle={props.clientTitle} companyName={props.companyName}/>
  </TestimonialWrapper>
)


export const Testimonials = ({ ...props }) => (
  <Section isLight={props.isLight} isCentered>
        <Row isCentered>
          <Subtitle isCentered isPrimary subtitle="What others say" />
        </Row>
        <Row isCentered> 
          <Column50 >
            <Testimonial clientName="Martin Taylor" companyName="Qurate" clientAvatar="https://app.growthmentor.com/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb2c3IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--028473c64ab0c52b1b57e58bb286332dd086ed83/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lJY0c1bkJqb0dSVlE2RW5KbGMybDZaVjkwYjE5bWFYUmJCMmtDTEFGcEFpd0IiLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--7df52312c78b0d761c82d554707ffd70ebc97f31/me.jpg" 
            testimonialText="Andreea was fantastic! I would definitely recommend her to anyone who is looking for help in GTM strategy and in both brand positioning 
             and building a content strategy for short and long term." />
          </Column50>
          <Column50 >
            <Testimonial clientName="Bart Donda" companyName="Prowly" clientAvatar="https://app.growthmentor.com/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbVFXIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--dd2c4b13c72d801d3faa2d138b51c3608743b33a/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lJY0c1bkJqb0dSVlE2RW5KbGMybDZaVjkwYjE5bWFYUmJCMmtDTEFGcEFpd0IiLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--7df52312c78b0d761c82d554707ffd70ebc97f31/Screen%20Shot%202021-06-10%20at%2010.50.39%20AM.png" 
            testimonialText="I definitely recommend setting up a call with her, as she'll surprise you with useful advice and workframes 
            that you can easily apply to your content marketing efforts and get results." /> 
          </Column50>
        </Row>
        <Row>
          <Column50>
            <Testimonial clientName="Kate Bojkov" companyName="EmbedSocial" clientAvatar="https://app.growthmentor.com/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0FDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--18b8f20d6085b7e8b7b32bf0e0b22e5d9ef9face/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lJYW5CbkJqb0dSVlE2RW5KbGMybDZaVjkwYjE5bWFYUmJCMmtDTEFGcEFpd0IiLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--78d607fe2e1db2da0c7004453e1c6d43e53bb331/Untitled%20design%20(1).jpg" 
            testimonialText="Andreea is a fantastic mentor! She is genuinely helpful and highly experienced in content strategy. We learned so much in a short time, and she helped us get some clarity in 
            our complex workflow." />
          </Column50>
          <Column50>
            <Testimonial clientName="Sid Bharath" companyName="Broca" clientAvatar="https://app.growthmentor.com/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBamtEIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ff6db96b6ee0addbdac41a361e66e0419dff0ceb/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lJYW5CbkJqb0dSVlE2RW5KbGMybDZaVjkwYjE5bWFYUmJCMmtDTEFGcEFpd0IiLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--78d607fe2e1db2da0c7004453e1c6d43e53bb331/28235670_10155859132185935_2936713444199134367_o.jpg" 
            testimonialText="Andreea was super helpful and extremely knowledgeable about marketing! She went above and beyond, providing 
            us with so many ideas and following up via email with more examples and templates." />   
          </Column50>
        </Row>
  </Section>
)
import React from "react"
import {
  ClientWrapper,
  ClientName,
  TestimonialAuthor,
  ClientAvatar,
  StyledDetailsWrapper,
  ClientTitle,
  CompanyName
} from "./style.js"

export const Client = ({ ...props }) => (
  <ClientWrapper>
    <ClientAvatar src={props.clientAvatar} alt="" />
    <TestimonialAuthor>
      <ClientName>{props.clientName} </ClientName>
      <CompanyName>{props.companyName}</CompanyName>
    </TestimonialAuthor>
  </ClientWrapper>
)

export const DetailsWrapper = ({ children, ...props }) => (
  <StyledDetailsWrapper isCentered={props.isCentered}>
    {children}
  </StyledDetailsWrapper>
)

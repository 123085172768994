import styled from "styled-components"
import { colors } from "../../styles/colors.js"
import { sizes, device } from "../../styles/sizes"
import { fontSizes } from "../../styles/typography"


export const StyledDetailsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => (props.isCentered ? "center" : "space-between")};
  width: 100%;
`

export const ClientTitle = styled.p`
  font-size: ${sizes.sizeM};
  line-height: ${sizes.sizeL};
  font-weight: 600;
  color: ${colors.colorContentDark};
  display: block;
  margin: ${props => (props.isCentered ? `0 auto 1rem` : `0 0 1rem`)};
  text-align: ${props => (props.isCentered ? `center` : ``)};
`

export const ClientName = styled.p`
  font-size: ${fontSizes.fontM};
  margin-bottom: 0;
  font-weight: 600;
  width: 200px;
`

export const ClientAvatar = styled.img`
  height: 84px;
  width: 84px;
  border-radius: 100%;
  display: inline-block;
  overflow: hidden;
  object-fit: cover;
  margin-right: ${sizes.sizeM};
  margin-left: 0;
`

export const ClientWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0;
  width: 100%;
`

export const TestimonialAuthor = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
  flex-flow: column wrap;

  @media ${device.laptop} {
    justify-content: flex-start;
    align-content: center;
    align-items: flex-start;
  }

  @media ${device.desktop} {
    justify-content: flex-start;
    align-content: center;
    align-items: center;
  }
`

export const CompanyName = styled.p`
font-size: ${fontSizes.fontS};
font-weight: 600;
line-height: ${fontSizes.fontM};
color: #C6B1BD;
margin: ${props => (props.isCentered ? `1rem auto 1rem` : `1rem 0rem 1rem`)};

@media ${device.tablet} {
  ${props => (props.isCentered ? `1rem auto 1rem` : `1rem 0rem 1rem`)};
  }

  @media ${device.laptop} {
    ${props => (props.isCentered ? `1rem auto 1rem` : `1rem 0rem 1rem`)};
  }

  @media ${device.desktop} {
    ${props => (props.isCentered ? `1rem auto 1rem` : `1rem 0rem 1rem`)};
  }
`